// Copyright 2014 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview
 * @author sheny@made-in-china.com
 * @Date: 14-12-9
 */

/**
 * @class SlideNav
 * @name SlideNav
 * @depends jQuery
 */
;(function(root, factory) {
    if (typeof module !== 'undefined' && module.exports) {// CommonJS
        module.exports = factory();
    } else if (typeof define === 'function' && define.amd) {// AMD / RequireJS
        define(factory);
    } else {
        root.SlideNav = factory.call(root);
    }
}(this, function() {
    'use strict';

    var single = null,
        win = window,
        doc = document,
        options = {
            containerTag : 'ul',
            containerCls : 'float-action-box',
            itemTag : 'li',
            // data-aliases 记录当前元素的别名，用于后面取得该元素对象
            template : '<li data-aliases="{aliases}" class="{aliases}"><a href="{link}" class="{cls}" rel="nofollow" target="_blank"></a>' +
                '<div class="popup-wrap"><div class="popup"><div class="popup-box">{text}<div class="arrow"><div class="arrow-inner"></div></div></div></div></div></li>',
            hoverCls : 'hover',
            language : 'en-US'
        },
        lang = {
            'en-US' : {
                'goTop' : 'Back to Top',
                'liveChat' : 'Live Chat',
                'tm' : {
                    onLine : 'Chat with Supplier',
                    offLine : 'Leave a message'
                },
                'contactNow' : 'Contact Now',
                'contact' : 'Contact Buyer Service',
                'survey' : 'Do Survey, Get Rewards',
                'help' : 'FAQs'
            },
            'zh-CN' : {
                'goTop' : '返回顶部',
                'liveChat' : '在线客服',
                'contact' : '联系买家服务',
                'survey' : '在线调查'
            }
        };

    function SlideNav(config) {
        if (single || (!win['jQuery'] && !win['$'])) return single;

        if (this instanceof SlideNav) {
            single = this;
        }

        this.c = $.extend(options, config);
        this.__init();
    }

    SlideNav.prototype = {
        constructor : this,
        __init : function () {
            this._ = {};
            this._.order = 0;
            this._.mapping = {};
            this._.language = lang[this.c.language] || {};
            this.carrier = doc.createElement(this.c.containerTag);
            this.carrier.style.display = 'none';
            this.carrier.className = this.c.containerCls || '';

            doc.body.appendChild(this.carrier);
        },
        __events : function(tag, uuid, handle) {
            if (this.c.hoverCls) {
                var _hc = this.c.hoverCls;
                $(tag).hover(function() {
                    $(this).children('.popup-wrap').addClass('open');
                }, function() {
                    $(this).children('.popup-wrap').stop().removeClass('open');
                });
            }

            if (!tag || !handle) return;
            $(tag).bind('click', uuid, handle);
        },
        __render : function(view) {
            if (!view) return;
            var _tpl = view.template || this.c.template;

            if (!view.link) {
                view.link = 'javascript:;';
            }

            if (!view.cls) {
                view.cls = '';
            }

            if (_tpl) {
                for (var item in view) {
                    _tpl = _tpl.replace(new RegExp('{' + item + '}', 'gi'), (view[item] || ''));
                }
            }

            return _tpl;
        },
        /**
         * 增加元素
         * @param data 预定义的类型，或者自定义对象
         * @param [handle] 点击事件回调函数
         */
        addItem : function(data, handle) {
            var uuid = 'slideNav_' + this._.order,
                item, render;

            this._.order++;

            if (typeof data === 'string' && this.getItem(data).length === 0) {
                item = SlideNav[data];
                item && (item.view.aliases = data);
                if (this._.language[data]) {
                    item.view.text = this._.language[data];
                }
            }

            if (data instanceof Object) {
                data.aliases = uuid;
                item = {
                    view : data,
                    handle : handle
                };
            }

            if (!item || (item.isShow && !item.isShow())) return;
            item.init && item.init();

            render = $(this.__render(item.view));
            $(this.carrier).append(render);
            this.__events(render, uuid, item.handle);

            if (!this._.mapping[uuid]) {
                this._.mapping[uuid] = item;
            }
        },
        /**
         * 获取别名元素
         * @param aliases 预定义的对象名，也可以根据自动生成规则取默认生成的别名
         * @returns {*|jQuery}
         */
        getItem : function(aliases) {
            return $(this.carrier).find(this.c.itemTag + '[data-aliases='+ aliases +']');
        },
        show : function() {
            this.carrier.style.display = 'block';
        },
        hide : function() {
            this.carrier.style.display = 'none';
        },
        getLanguage : function() {
            return this._.language;
        }
    };

    return SlideNav;
}));
// Copyright 2014 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview
 * @author sheny@made-in-china.com
 * @Date: 14-12-10
 */

/**
 * 扩展 SlideNav 默认事件及相关参数，方便调用
 * @type ['goTop', 'liveChat', 'tm', 'contactNow', 'contact', 'survey', 'help']
 * @param init {Function} 在渲染元素之前初始化函数，相当于beforeRenderHTML
 * @param isShow {Function} 用于判断某个对象是否需要显示，在render之前进行判断，false则不会增加此元素。
 * @param ['aliases', 'link', 'text', 'cls'] 这里是默认模板支持的属性
 * 这些属性可以自己定义，并通过view对象传入即可，但是template属性为必须属性，用于重载默认模板
 * @example
 *  sn.addItem({ a:123, b:321, template : '<div>{a}{b}</div>'}, function() {
        alert('ceshi');
    });
 */

if (window['SlideNav']) {
    /* style */
    var style = document.createElement('link');
    style.type = 'text/css';
    style.rel = 'stylesheet';
    style.href = 'https://crov.micstatic.com/common/js/biz/slideNav/float-action-box_a0217edd.css?t='+ new Date().getTime();
    document.getElementsByTagName('head')[0].appendChild(style);

    SlideNav.defaultsInit = function(sn) {
        var sw = parseInt(window.screen.width, 10);
        function togglePosition() {
            var pb = 150,
                hb = 10,
                vh = document.documentElement.clientHeight,
                vw = document.documentElement.clientWidth,
                bh = document.body.scrollHeight,
                _pos = {},
                _holder = {}, pw;

            if ($('#J-page-footer').length > 0) {
                hb = parseInt($('#J-page-footer')[0].clientHeight, 10) - pb + 10;
            }

            if (vw <= 1200) {
                _pos.left = null;
                _pos.right = 10;
            } else {
                _pos.right = null;
                if ($('.grid').length > 0) {
                    pw = parseInt($('.grid').eq(0).width(), 10);
                    if ((vw - pw) / 2 <= 50 || (sw - pw) / 2 <= 60) {
                        _holder.left = (pw / 2) - 54;
                    } else {
                        _holder.left = (pw / 2) + 10;
                    }
                } else {
                    _holder.left = 505;
                }
            }

            // 如果body小于整个可视区域，则距离顶部整个页面高度的一半
            if (vh > bh) {
                _pos.top = bh / 2;
            } else {
                _pos.bottom = pb;
                _holder.bottom = hb;
            }

            // 去除自动匹配位置功能，SB
            _pos.right = 20;
            _pos.left = null;

            _holder.left = null;
            return { pos : _pos, holder : _holder };
        }

        if (window['JFixed']) {
            var p = togglePosition();
            var jf = new JFixed({
                carrier : sn.carrier,
                holder : p.holder,
                position : p.pos
            });

            if (sn.getItem('goTop')) {
                jf.onScroll(function() {
                    SlideNav.goTop.toggle(sn.getItem('goTop'));
                });
                SlideNav.goTop.toggle(sn.getItem('goTop'));
            }

            jf.fixed();

            if (jf.position) {
                var delay = null;
                $(window).bind('resize', function() {
                    if (delay) return;
                    delay = setTimeout(function() {
                        var p = togglePosition();
                        jf.position(p.pos, p.holder);
                        //todo 修改JFixed组件替换调用内置方法
                        jf.__holdCheck();
                        clearTimeout(delay);
                        delay = null;
                    }, 300);
                });
            }
        }
    };

    SlideNav.goTop = {
        view : { text : 'Back to Top', cls : 'f-go-top' },
        handle : function(e) {
            $('html, body').animate({ scrollTop : 0 }, 200);
            e.preventDefault();
        },
        toggle : function(goTop) {
            var t = document.body.scrollTop || document.documentElement.scrollTop || 0,
                //sh = window.screen.availHeight - 80,
                vh = document.documentElement.clientHeight,
                bh = document.body.scrollHeight;

            if (Math.floor(bh / vh) >= 2 && t > (vh * 0.7)) {
                $(goTop).css('visibility', 'visible');
                $(goTop).prev().removeClass('li-visible');
            } else {
                $(goTop).css('visibility', 'hidden');
                $(goTop).prev().addClass('li-visible');
            }
        }
    };

    SlideNav.liveChat = {
        view : { text : 'Live Chat', cls : 'f-live-chat', link : null },
        handle : function(e) {
            e.preventDefault();
            e.stopPropagation();

            $.ajax({
                url: '//www.crov.com/livechat/getAccountSnapUrl',
                type: 'get',
                data: {type: '0'},
                dataType: 'jsonp',
                jsonp: "jsoncallback",
                success: function (response) {
                    if(typeof response === "string"){
                        response = JSON.parse(response)
                    }

                    if (response.code === '10001') {
                        var kefuUrl = '//kefu.trademessenger.com/chat?domain=micen&businessType=7sRSdqnOW_0';
                        // p版测试用
                        // var kefuUrl = '//kefu.trademessenger.com/chat?domain=micen&businessType=rXpOdM7CxrA';

                        if(response.data.status === '000000' && response.data.data){
                            var accountSnapUrl = response.data.data;
                            var token = accountSnapUrl.substring(accountSnapUrl.indexOf('?token=') + 7);
                            kefuUrl = kefuUrl + '&token=' + token + '&accountSnapUrl=' + accountSnapUrl;
                        }

                        window.open(kefuUrl, 'kefu',
                            'toolbar=no,location=no,directories=no,resizable=yes,status=yes,menubar=no,scrollbars=yes,width=800,height=600,left=0,top=0');

                    }
                },
                error: function () {

                }
            });

        }
    };

    SlideNav.tm = {
        view : { text : 'Talk to Me', cls : 'f-tm-on' },
        handle : null,
        isShow : function() {
            return !!$('#J-SlideNav-TM').length;
        },
        init : function() {
            if ($('#J-SlideNav-TM').length > 0) {
                SlideNav.tm.view = {
                    text : 'Talk to Me',
                    aliases : 'tm',
                    cls : 'f-tm-on',
                    dataId : $('#J-SlideNav-TM').attr('dataid'),
                    processor : 'fixed2',
                    cid : $('#J-SlideNav-TM').attr('cid'),
                    template : '<li data-aliases="{aliases}"><a href="{link}" style="display:none;" dataId="{dataId}" processor="{processor}" cid="{cid}" ' +
                        'class="{cls}" rel="nofollow" target="_blank"></a><div class="popup-wrap">' +
                        '<div class="popup"><div class="popup-box"><span>{text}</span><div class="arrow"><div class="arrow-inner"></div></div></div></div></div></li>'
                };

                SlideNav.tm.__waitTM(function() {
                    var _this = new SlideNav();
                    var $el = _this.getItem('tm');
                    var fixed = function(holder){
                        this.$elem = jQuery('<a rel="nofollow" class="f-tm-on" href="javascript:void(\'Chat Now!\')"></a>');
                        this.$elem.insertAfter(holder);
                    };

                    fixed.prototype = {
                        online: function(callback){
                            this.$elem.removeClass('f-tm-off').show();
                            this.$elem.parent().find('span').html(_this.getLanguage().tm.onLine || '');
                            typeof callback === 'function' && callback();
                        },
                        offline: function(callback){
                            this.$elem.addClass('f-tm-off').show();
                            this.$elem.parent().find('span').html(_this.getLanguage().tm.offLine || '');
                            typeof callback === 'function' && callback();
                        }
                    };

                    executeTM({
                        params: {
                            holderSelector: $el.find('a')
                        },
                        render: {
                            processors: {
                                fixed2: fixed
                            }
                        }
                    });
                });
            }
        },
        __waitTM : function(fn) {
            var _timer = null;
            if (!window['executeTM'] || (new SlideNav()).getItem('tm').length === 0) {
                _timer = setInterval(function() {
                    if (window['executeTM'] && (new SlideNav()).getItem('tm').length > 0) {
                        clearInterval(_timer);
                        fn && fn();
                    }
                }, 1000);
            }else{
                fn && fn();
            }
        }
    };

    SlideNav.contactNow = {
        view : { text : 'Contact Now', cls : 'f-contact' },
        init : function() {
            if ($('#J-SlideNav-Contact').length > 0 && $('#J-SlideNav-Contact').val()) {
                SlideNav.contactNow.view.link = $('#J-SlideNav-Contact').val();
            }
        },
        isShow : function() {
            if ($('#J-SlideNav-Contact').length > 0 && $('#J-SlideNav-Contact').val()) {
                return true;
            }
            return false;
        }
    };

    SlideNav.contact = {
        view : { text : 'Contact Buyer Service', cls : 'f-contact-buyer-service', link : 'http://sourcing.made-in-china.com/trades-service-from/trds-contact/index/contact-us.html' },
        handle : null
    };

    SlideNav.survey = {
        view : { text : 'Do Survey, Get Rewards', cls : 'f-survey' },
        handle : null,
        init : function() {
            var placeholder = $('#J-SlideNav-Survey');
            var isLogin = !!placeholder.attr('isLogin'),
                isAbroadIP = !!placeholder.attr('isAbroadIP'),
                comId = placeholder.attr('comId'),
                isBuyer = !!placeholder.attr('isBuyer');

            // 国内外用户统一问卷调查
            if (typeof SlideNav.survey.view.link === 'string') {
                SlideNav.survey.view.link = SlideNav.survey.view.link + comId;
            }
            // 国内外用户问卷调查区分
            if (SlideNav.survey.view.link instanceof Array) {
                if (!isLogin && isAbroadIP) {
                    SlideNav.survey.view.link = SlideNav.survey.view.link[0] + comId;
                }

                if (isLogin && isBuyer) {
                    SlideNav.survey.view.link = SlideNav.survey.view.link[1] + comId;
                }
            }
        },
        isShow : function() {
            var placeholder = $('#J-SlideNav-Survey');

            if (!placeholder || !SlideNav.survey.view.link) {
                return false;
            }

            var isLogin = !!placeholder.attr('isLogin'),
                isAbroadIP = !!placeholder.attr('isAbroadIP'),
                comId = placeholder.attr('comId'),
                isBuyer = !!placeholder.attr('isBuyer');
            if (((!isLogin && isAbroadIP) || (isLogin && isBuyer)) && comId) {
                return true;
            }

            return false;
        }
    };

    SlideNav.help = {
        view : { text : 'Help', cls : 'f-help', link: '//www.crov.com/help/' },
        handle : null
    };

    SlideNav.dsfaq = {
      view : { text : 'FAQs', cls : 'f-help', link: '//dropshipping.crov.com/faqs/top-questions.html' },
      handle : null
    }
}
/**
 * @depends JFixed
 * @type ['goTop', 'liveChat', 'tm', 'contactNow', 'contact', 'survey', 'help']
 * @example
 *
 *
    $(window).bind('load', function() {
        var sn = new SlideNav();
        sn.addItem('goTop');
        sn.addItem('liveChat');
        sn.addItem('tm');
        sn.addItem('contactNow');
        sn.addItem('contact');
        sn.addItem('survey');
        sn.addItem('help');
       // sn.addItem({ text : '123', template : '<div data-aliases="{aliases}">{text}</div>' });
        SlideNav.defaultsInit(sn);
        sn.show();
    });
 */